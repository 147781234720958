import React, { Suspense } from "react";
import Logo from "./images/d wallet logo final svg-01.svg";
import './style.css';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { FaApple } from 'react-icons/fa';
import { BsDownload } from 'react-icons/bs';
import FormControlLabel from '@mui/material/FormControlLabel';
import comingsoon from './images/coming soon2-01-01-01.png';
import Chrome from './images/extension-logo/Chrome.png';
import Edge from './images/extension-logo/Edge.png';
import Opera from './images/extension-logo/Opera.png';
import Brave from './images/extension-logo/brave.png';
import chrome from './images/extension-logo/video/chrome.mp4';
import opera from './images/extension-logo/video/opera.mp4';
import brave from './images/extension-logo/video/brave.mp4';

import Apk from "../src/D-Wallet.apk"
import DwalletExtension2024 from "../src/D-wallet-Extension-2024.zip"

const Footer = React.lazy(() => import('./Footer'));
const MaterialUISwitch = React.lazy(() => import('./Dark'));
const Error = React.lazy(() => import('./Pages/404'));
const Cookies = React.lazy(() => import('./Pages/Cookies'));
const Licenseaggrement = React.lazy(() => import('./Pages/Licenseaggrement'));
const Networks = React.lazy(() => import('./Pages/Networks'));
const Attributions = React.lazy(() => import('./Pages/Attributions'));
const Terms = React.lazy(() => import('./Pages/Terms'));
const Privacy = React.lazy(() => import('./Pages/Privacy'));
const Home = React.lazy(() => import('./Home'));

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNav: false };
    this.state = {
      isActive: false,
    };
    this.toggleNav = this.toggleNav.bind(this);

  }


  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  toggleNav() {
    this.setState({
      showNav: !this.state.showNav
    })
  }





  render() {
    const { showNav } = this.state
    const isActive = this.state.isActive;
    return (

      <div className={isActive ? "light-mode" : "dark-mode"}>

        <Router>
          <Suspense fallback={<div></div>}>



            <nav className="navbar navbar-expand-xl navbar-light fixed-top" id="navbar">
              <div className="container-fluid px-lg-5">
                <Link className="navbar-brand" to="/"><img src={Logo} alt="logo" id="navbar-img" /></Link>
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item dark-white-mobile">
                    <label>
                      <span className="nav-link">
                        <FormControlLabel control={<MaterialUISwitch defaultChecked onClick={this.handleToggle} />} />
                      </span>
                    </label>
                  </li>
                </ul>
                <button className="navbar-toggler border" id="dlDropDown" type="button" onClick={this.toggleNav}>
                  <span className="navbar-toggler-icon"><span className="fs-toggle text-white">.</span></span>
                </button>

                <div className={(showNav ? 'show' : '') + ' collapse navbar-collapse'} ref="mynav">
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a className="nav-link nav-items-tex" rel="noreferrer" target="_blank" href="https://network.d-wallet.io/" >Networks</a>
                    </li>

                    <li className="nav-item pt-2 ms-3">
                      <a target="_blank" href={Apk} className="extension-button-nav"><IoLogoGooglePlaystore className="fs-16" /> Google Play</a>
                    </li>
                    <li className="nav-item pt-4 pt-lg-2 ms-3">
                      <a className="extension-button-nav" data-bs-toggle="modal" data-bs-target="#exampleModal"><FaApple className="mb-1 fs-16" /> App Store</a>
                    </li>
                    <li className="nav-item pt-4 pt-lg-2 ms-3">
                      <a className="extension-button-nav" rel="noreferrer" data-bs-toggle="modal" href="#extensionmultiToggle" role="button" onClick={this.toggleNav}><BsDownload className="mb-1 me-2 fs-16" />Extension</a>

                      {/* <a className="extension-button-nav" rel="noreferrer" href={DwalletExtension2024} target="_blank" onClick={this.toggleNav}><BsDownload className="mb-1 me-2 fs-16" />Extension</a> */}
                    </li>
                    <li className="nav-item dark-white-desktop">
                      <span className="nav-link">
                        <label>
                          <FormControlLabel control={<MaterialUISwitch defaultChecked onClick={this.handleToggle} />} />
                        </label>
                      </span>
                    </li>

                  </ul>
                </div>
              </div>
            </nav>

            <Switch>
              <Route exact path='/' component={Home}></Route>
              <Route exact path='/attributions' component={Attributions}></Route>
              <Route exact path='/privacy-policy' component={Privacy}></Route>
              <Route exact path='/terms' component={Terms}></Route>
              <Route exact path='/networks' component={Networks}></Route>
              <Route exact path='/license' component={Licenseaggrement}></Route>
              <Route exact path='/cookies' component={Cookies}></Route>
              <Route exact path='*' component={Error}></Route>
            </Switch>

            <Footer />
          </Suspense>

        </Router>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">

                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img src={comingsoon} alt="comingsoon" />
              </div>

            </div>
          </div>
        </div>


        <div class="modal fade" id="extensionmultiToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-dialog-effect">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body p-lg-5">
                <h1 className="text-center extensionmulti-text-1">Explore how to import and use our Wallet extension here</h1>
                <div class="row row-cols-2 row-cols-lg-4 g-4 my-3 extensionmulti-card">
                  <div class="col">
                    <div class="card">
                      <img src={Chrome} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">Chrome</h5>
                      </div>

                      <div class="card-footer border-0 text-center">
                        <button className="extensionmulti-button" type="button" data-bs-target="#exampleModalChrome" data-bs-toggle="modal">Download Extension</button>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src={Opera} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">Opera</h5>
                        <div className="text-center">
                        </div>
                      </div>
                      <div class="card-footer border-0 text-center">
                        <button className="extensionmulti-button" type="button" data-bs-target="#exampleModalopera" data-bs-toggle="modal">Download Extension</button>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <img src={Brave} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">Brave</h5>
                      </div>
                      <div class="card-footer border-0 text-center">
                        <button className="extensionmulti-button" type="button" data-bs-target="#exampleModalbrave" data-bs-toggle="modal">Download Extension</button>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="card">
                      <img src={Edge} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">Edge</h5>
                      </div>
                      <div class="card-footer border-0 text-center">
                        <button className="extensionmulti-button" type="button" data-bs-target="#exampleModalEdge" data-bs-toggle="modal">Download Extension</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalChrome" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-dialog-effect">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h1 className="text-center extensionmulti-text-2 mb-4">Check out the end-to-end setup procedure for D-wallet in Chrome Browser</h1>
                <div className="row justify-content-center">
                  <div className="col-10 ">
                    <iframe width="100%" className="iframe-video" src="https://www.youtube.com/embed/s1FIEC_q9oU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div class="card-footer border-0 text-center mb-5" >
                <a className="extensionmulti-button-2 me-lg-3 mb-lg-0 mb-4" type="button" rel="noreferrer" href="" target="_blank">Add Extension</a>

                <a className="extensionmulti-button-2" type="button" rel="noreferrer" href={DwalletExtension2024} target="_blank">Download Extension</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalopera" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-dialog-effect">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h1 className="text-center extensionmulti-text-2 mb-4">Check out the end-to-end setup procedure for D-wallet in Opera Browser</h1>
                <div className="row justify-content-center">
                  <div className="col-10 ">
                    <iframe width="100%" className="iframe-video" src="https://www.youtube.com/embed/meoo06-9160" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                  </div>
                </div>
              </div>
              <div class="card-footer border-0 text-center mb-5" >
                <a className="extensionmulti-button-2 me-lg-3 mb-lg-0 mb-4" type="button" rel="noreferrer" href="" target="_blank">Add Extension</a>
                <a className="extensionmulti-button-2" type="button" rel="noreferrer" href={DwalletExtension2024} target="_blank">Download Extension</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalbrave" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-dialog-effect">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h1 className="text-center extensionmulti-text-2 mb-4">Check out the end-to-end setup procedure for D-wallet in Brave Browser</h1>
                <div className="row justify-content-center">
                  <div className="col-10 ">
                    <iframe width="100%" className="iframe-video" src="https://www.youtube.com/embed/V0Y4kswgT7U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                  </div>
                </div>
              </div>
              <div class="card-footer border-0 text-center mb-5" >
                <a className="extensionmulti-button-2 me-lg-3 mb-lg-0 mb-4" type="button" rel="noreferrer" href="" target="_blank">Add Extension</a>

                <a className="extensionmulti-button-2" type="button" rel="noreferrer" href={DwalletExtension2024} target="_blank">Download Extension</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalEdge" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-dialog-effect">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h1 className="text-center extensionmulti-text-2 mb-4">Check out the end-to-end setup procedure for D-wallet in Edge Browser</h1>
                <div className="row justify-content-center">
                  <div className="col-10 ">
                    <iframe width="100%" className="iframe-video" src="https://www.youtube.com/embed/WdlXqFtRFOY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                  </div>
                </div>
              </div>
              <div class="card-footer border-0 text-center mb-5" >
                <a className="extensionmulti-button-2 me-lg-3 mb-lg-0 mb-4" type="button" rel="noreferrer" href="" target="_blank">Add Extension</a>

                <a className="extensionmulti-button-2" type="button" rel="noreferrer" href={DwalletExtension2024} target="_blank">Download Extension</a>
              </div>
            </div>
          </div>
        </div>



      </div>


    );
  }

}







export default Nav;